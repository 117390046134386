<template>
  <el-dialog
    title="利率设置"
    :visible.sync="showSetRate"
    center
    width="450px"
    top="20vh"
    append-to-body
    @close="$emit('cancel')"
  >
    <el-form label-width="120px" class="form-container">
      <el-form-item label="利率：">
        <tinyt-input
          width="200"
          isNum
          v-model="rateValue"
          placeholder="请输入利率"
        ></tinyt-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancelRate">取消</el-button>
      <el-button :loading="subRateLoading" @click="handleSubRate"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { changeRates } from "@/api/account";
export default {
  props: {
    show: Boolean,
    selectedList: Array,
  },
  data() {
    return {
      showSetRate: false,
      subRateLoading: false,
      rateValue: null,
    };
  },
  watch: {
    show: function (nv) {
      if (nv) {
        this.showSetRate = true;
      }
    },
  },
  methods: {
    handleSubRate() {
      if (!this.rateValue) return this.$message.error("请填写利率");
      let ids = this.selectedList.map((item) => {
        return item.id;
      });
      this.subRateLoading = true;
      changeRates(ids.join(","), this.rateValue)
        .then((res) => {
          this.showSetRate = false;
          this.rateValue = null;
          this.$message.success("设置成功");
          this.$emit("success");
        })
        .finally(() => {
          this.subRateLoading = false;
        });
    },
    handleCancelRate() {
      this.rateValue = null;
      this.showSetRate = false;
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>
<template>
  <!-- <el-dialog
      :visible.sync="show"
      width="970px"
      center
      top="10vh"
      :before-close="handleClose"
      :close-on-click-modal=false
      :append-to-body="true"
      :destroy-on-close="true"
      :show-close=false> -->
  <div class="bind-user">
    <div class="container">
      <div class="flex-between">
        <el-button
          :disabled="selectAccount.length === 0"
          type="primary"
          @click="isSelectTime = true"
          >批量设置日期</el-button
        >
        <el-button icon="el-icon-plus" @click="dialogShow = true"
          >添加管理者</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        height="650px"
        :data="selectItems"
        @selection-change="handleSelectionChange"
        style="width: 100%; margin-top: 15px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="adminUserId"
          align="center"
          width="80px"
          label="用户ID"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="adminUsername"
          label="用户名称"
        >
        </el-table-column>
        <el-table-column
          width="180px"
          prop="bindStartDate"
          align="center"
          label="统计开始日期"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.bindStartDate"
              type="date"
              :picker-options="pickOptions"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
          width="180px"
          prop="bindEndDate"
          align="center"
          label="统计结束日期"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.bindEndDate"
              type="date"
              :picker-options="endPickOption"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="是否为测书号" width="170px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isTest"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <el-input
              style="width: 100px; margin-left: 10px"
              v-if="scope.row.isTest === 1"
              v-model="scope.row.totalCostAmount"
              type="number"
              placeholder="总消耗金额,0为不限制"
            />
          </template>
        </el-table-column>
        <!-- 新增是否智能 -->
        <el-table-column label="是否智能" width="100px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isAuto"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- 新增自动结束时间 -->
        <el-table-column
          prop="autoEndDate"
          align="center"
          width="180px"
          label="结束日期"
        >
          <template slot-scope="scope">
            <el-date-picker
              clearable
              style="width: 150px"
              value-format="yyyy-MM-dd"
              v-model="scope.row.autoEndDate"
              type="date"
              :picker-options="endPickOption"
              placeholder="选择日期"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60px">
          <template slot-scope="scope">
            <el-button
              type="danger"
              @click="selectItems.splice(scope.$index, 1)"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="warning" @click="handleReset">清除设置</el-button>
      <el-button @click="selectItems = []" type="danger">清除选择</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >确 定</el-button
      >
    </span>
    <select-dept-user
      :show="dialogShow"
      :onlyUser="true"
      :multiple="true"
      @close="dialogShow = false"
      @confirm="confirm"
    ></select-dept-user>
    <!-- <el-dialog
      title="选择管理者"
      :visible.sync="dialogShow"
      width="500px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="
        () => {
          this.dialogShow = false;
          this.selectUser = [];
        }
      "
    >
      <el-form label-width="80px" style="height: 200px">
        <el-form-item>
          <el-radio v-model="checkAll" :label="1" border size="medium"
            >全部用户</el-radio
          >
          <el-radio v-model="checkAll" :label="0" border size="medium"
            >部分用户</el-radio
          >
        </el-form-item>
        <el-form-item label="用户" v-if="checkAll === 0">
          <el-select
            v-model="selectUser"
            placeholder="请选择"
            clearable
            multiple
            value-key="id"
            style="width: 100%"
            filterable
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogShow = false;
            selectUser = [];
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="handleSelectSubmit">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      title="选择统计开始日期"
      :visible.sync="isSelectTime"
      width="500px"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="() => (this.isSelectTime = false)"
    >
      <el-form>
        <el-form-item label="设置时间">
          <el-radio v-model="setTimeType" :label="0" border size="medium"
            >开始日期</el-radio
          >
          <el-radio v-model="setTimeType" :label="1" border size="medium"
            >结束日期</el-radio
          >
        </el-form-item>
        <el-form-item label="统计日期">
          <el-date-picker
            v-model="selectTime"
            value-format="yyyy-MM-dd"
            type="date"
            :picker-options="setTimeType === 0 ? pickOptions : endPickOption"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isSelectTime = false">取 消</el-button>
        <el-button type="primary" @click="handleSelectTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setUserAccount, getAccountUser } from "@/api/account";
import { mapGetters } from "vuex";
import SelectDeptUser from "./selectDeptUser";

export default {
  name: "bindUser",
  props: {
    show: Boolean,
    channel: Object,
  },
  data() {
    return {
      loading: false,
      selectAccount: [], // 选中用户
      selectItems: [], // 选择用户
      selected: [], // 已经选择的管理员
      checkAll: 0,
      dialogShow: false,
      selectUser: [],
      selectTime: null,
      setTimeType: 0,
      isSelectTime: false,
      submitLoading: false,
      pickOptions: {
        disabledDate(time) {
          return (
            time < new Date("2020-01-01 00:00:00").getTime() ||
            time > Date.now()
          );
        },
      },
      endPickOption: {
        disabledDate(time) {
          return time < new Date("2020-01-01 00:00:00").getTime();
        },
      },
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.loading = true;
          getAccountUser(this.channel.id, 2)
            .then((res) => {
              this.selectItems = res.map((item) => {
                return {
                  adminUserId: item.adminUserId,
                  adminUsername: item.adminUsername,
                  bindStartDate: item.bindStartDate,
                  bindEndDate: item.bindEndDate,
                  isTest: item.isTest,
                  totalCostAmount: item.totalCostAmount,
                  isAuto: item.isAuto,
                  autoEndDate: item.autoEndDate,
                };
              });
              // this.selected = res.map(item => {
              //   return {
              //     name: item.adminUsername,
              //     relationId: item.adminUserId,
              //     type: 1
              //   };
              // });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  methods: {
    handleReset() {
      this.selectItems.forEach((item) => {
        item.bindStartDate = null;
        item.bindEndDate = null;
        item.isTest = 0;
        item.isAuto = 0;
        item.autoEndDate = null;
        item.totalCostAmount = null;
      });
    },
    handleSubmit() {
      const { selectItems } = this;
      if (selectItems.length === 0) {
        this.$message.error("请选择用户");
        return false;
      }
      const list = [];
      let hasDateEmpty = false;
      let hasAmountEmpty = false;
      for (const item of selectItems) {
        if (item.bindStartDate) {
          const temp = {
            accountChannelId: this.channel.id,
            bindStartDate: item.bindStartDate,
            bindEndDate: item.bindEndDate,
            adminUserId: item.adminUserId,
            isTest: item.isTest,
            isAuto: item.isAuto,
            autoEndDate: item.autoEndDate,
          };
          if (item.isTest === 1) {
            if (
              item.totalCostAmount ||
              typeof item.totalCostAmount === "number"
            ) {
              temp.totalCostAmount = Number(item.totalCostAmount);
            } else {
              hasAmountEmpty = true;
              break;
            }
          }
          list.push(temp);
        } else {
          hasDateEmpty = true;
          break;
        }
      }
      if (hasDateEmpty) {
        this.$message.warning("请为每个管理者选择对应统计开始时间");
        return false;
      }
      if (hasAmountEmpty) {
        this.$message.warning("请为测试号输入对应总消耗金额");
        return false;
      }
      this.submitLoading = true;
      setUserAccount({
        accountType: 3,
        relations: list,
      })
        .then(() => {
          this.$message.success("设置成功");
          this.handleClose();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    handleSelectTime() {
      this.isSelectTime = false;
      this.loading = true;
      const { selectTime, selectItems, selectAccount, setTimeType } = this;
      this.selectItems = selectItems.map((item) => {
        const account = {
          adminUserId: item.adminUserId,
          adminUsername: item.adminUsername,
          isTest: item.isTest,
          totalCostAmount: item.totalCostAmount,
        };
        if (setTimeType === 0) {
          account.bindEndDate = item.bindEndDate;
          account.bindStartDate = selectAccount.includes(item.adminUserId)
            ? selectTime
            : item.bindStartDate;
        } else {
          account.bindStartDate = item.bindStartDate;
          account.bindEndDate = selectAccount.includes(item.adminUserId)
            ? selectTime
            : item.bindEndDate;
        }
        return account;
      });
      this.loading = false;
      this.setTimeType = 0;
      this.selectTime = null;
    },
    handleSelectSubmit() {
      this.dialogShow = false;
      this.loading = true;
      this.selectItems = this.selectItems.concat(
        this.checkAll === 0
          ? this.selectUser.map((item) => {
              return {
                adminUserId: item.id,
                adminUsername: item.name,
                bindStartDate: null,
                bindEndDate: null,
                isTest: 0,
              };
            })
          : this.userList.map((item) => {
              return {
                adminUserId: item.id,
                adminUsername: item.name,
                bindStartDate: null,
                bindEndDate: null,
                isTest: 0,
              };
            })
      );
      this.loading = false;
      this.selectUser = [];
      this.checkAll = 0;
    },
    handleSelectionChange(row) {
      this.selectAccount = row.map((item) => item.adminUserId);
    },
    handleClose() {
      this.$emit("close");
      this.selectItems = [];
      this.checkAll = 0;
    },
    confirm(selected) {
      const obj = {};
      const unTreatedData = [
        ...this.selectItems,
        ...selected.map((item) => {
          return {
            adminUserId: item.id,
            adminUsername: item.name,
            bindStartDate: null,
            bindEndDate: null,
            isTest: 0,
          };
        }),
      ];
      this.selectItems = unTreatedData.reduce((acc, b, c) => {
        if (!(`${b.adminUserId}` in obj)) {
          acc.push(b);
          obj[`${b.adminUserId}`] = b;
        }
        return acc;
      }, []);
      // this.selected = this.selectItems.map(item => {
      //   return {
      //     name: item.adminUsername,
      //     relationId: item.adminUserId,
      //     type: 1
      //   };
      // });
      this.selected = selected;
      this.dialogShow = false;
    },
  },
  components: {
    SelectDeptUser,
  },
};
</script>

<style scoped>
.header {
  /* position: absolute;
  top: 0;
  left: 0; */
  padding: 15px 35px;
  font-size: 20px;
  width: 100%;
  background: #eceff4;
  text-align: center;
}
.bind-user {
  width: 1200px;
  box-sizing: border-box;
  padding: 20px;
}
.container {
  padding-top: 20px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>

<template>
  <el-dialog
    title="公众号标签"
    :visible.sync="showCategory"
    center
    width="600px"
    top="20vh"
    append-to-body
    @close="$emit('close')"
  >
    <el-form label-width="120px" class="form-container">
      <el-form-item label="标签">
        <el-select
          class="margin-right-twentyFour"
          style="width: 400px"
          v-model="targetItems"
          multiple
          filterable
          placeholder="选择标签"
        >
          <el-option
            v-for="item in selectItems"
            :key="item.categoryId"
            :value="item.categoryId"
            :label="item.categoryName"
          >
            <span style="float: left">{{ item.categoryName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="submitLoading" @click="handleSubmit">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getChannelCategory,
  editChannelCategory,
} from "@/api/account";

export default {
  name: "slectCategory",
  props: {
    show: Boolean,
    id: Number,
    selectItems: {
      type: Array,
      default: () => [],
    },
    selectedList: Array,
    isMultiple: Boolean,
  },
  data() {
    return {
      showCategory: false,
      targetItems: [],
      submitLoading: false,
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.showCategory = true;
          if (!this.isMultiple) {
            this.submitLoading = true;
            getChannelCategory(this.id)
              .then((res) => {
                this.targetItems = res.map((item) => item.categoryId);
              })
              .finally(() => (this.submitLoading = false))
              .catch(() => {
                this.submitLoading = false;
              })
          } else {
            this.targetItems = [];
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.targetItems = [];
      this.showCategory = false;
    },
    handleSubmit() {
      let ids = [this.id];
      if (this.isMultiple) {
        ids = this.selectedList.map((item) => {
          return item.id;
        });
      }
      editChannelCategory({
        accountChannelIds: ids,
        categoryIds: this.targetItems
      }).then(() => {
        this.$message.success('保存成功！')
        this.handleClose()
        this.$emit("refresh");
      })
    },
  }
}
</script>
